<template>
    <section>
        <div class="details-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4 pb-3" v-for="(item,itemID) in items" :key="itemID">
                        <div class="card card-icon-top">
                            <div class="card-body text-center">
                                <img :src="item['card-image-url']" alt="image" class="card-icon">
                                <h4>{{item[title]}}</h4>
                                <p class="card-text">{{item[description]}}</p>
                            </div>
                            <div class="card-footer" v-if="editable">
                                <button class="btn" @click="$emit('edit',item)"><i class="fa fa-pencil"></i></button>
                                <button class="btn" @click="deleteCard(item)"><i class="fa fa-trash-o"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'card-icon-top',
    data () {
        return {
            items: []
        }
    },
    props: {
        title: String,
        description: String,
        card: Array,
        editable: Boolean
    },
    methods: {
        deleteCard(item) {
            let index = this.items.indexOf(item)
            console.log(index)
            this.items.splice(index, 1)
        }
    },
    mounted () {
        this.items = this.card
    }
}
</script>